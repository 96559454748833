<div class="container-fluid d-flex justify-content-center align-items-center w-100 h-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>
		<div class="card-body w-100">
			<div class="d-flex flex-column justify-content-between align-items-center">
				<div class="mt-4 col-sm-12 col-md-12">
					<div *ngIf="!user">
						<app-dashboard-login (handleSuccess)="handleLoginSuccess($event)"
							(handleError)="handleLoginFailure($event)"></app-dashboard-login>
					</div>
					<div *ngIf="user && !changePasswordScreen">
						<div class="ps-3 pe-3">
							<div class="row">
								<div class="col text-start text-bold">
									<span>{{'dashboard' | translate}}</span>
									<hr />
								</div>
								<div class="col-1 text-end">
									<img src="assets/images/angola/logo.png" height="28px">
								</div>
								<div class="col-1"></div>
							</div>
						</div>						
						<app-user-table></app-user-table>
					</div>
					<div *ngIf="changePasswordScreen">
						<app-change-password></app-change-password>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>