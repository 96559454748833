<div class="card-header bg-danger text-white">
    <div class="d-flex justify-content-between align-items-center">
        <div>
            <img src="assets/images/angola/logo_mpla.png" height="28px">            
        </div>
        <div>
            <span>{{'title' | translate}}</span>
        </div>
    </div>
</div>
