import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { RefService } from "src/app/service/ref.service";

@Component({
    selector: "app-home",
    templateUrl: "./home.component.html",
    styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
    ref: string = null;
    continuePossible: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private refService: RefService,
        private translateService: TranslateService,
    ) {}

    ngOnInit(): void {
        this.activatedRoute.queryParamMap.subscribe((params) => {
            this.ref = params.get("ref");
            this.refService.setRef(this.ref);
            this.continuePossible = true;
        });

        if (!this.ref) {
            const ref = Math.random().toString(36).substr(2, 5);
            this.refService.setRef(ref);
            this.continuePossible = true;
        }
    }

    continue() {
        this.router.navigate(["/consent"]);
    }
}
