import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { DashboardUserService } from "src/app/service/dashboard.user.service";

@Component({
    selector: "app-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent {
    user: string | null;
    constructor(
        private router: Router,
        private toastr: ToastrService,
        private dashboardUserService: DashboardUserService,
    ) {}
    ngOnInit() {
        this.user = this.dashboardUserService.getUser();
    }

    onClose() {
        this.router.navigate([""]);
    }

    handleLoginSuccess(event: string) {
        this.user = event;
        this.dashboardUserService.setUser(event);
    }

    handleLoginFailure(event: string) {
        this.user = null;
        this.toastr.error(event);
    }
}
