import { Injectable } from "@angular/core";
import { Config } from "protractor";

@Injectable({
    providedIn: "root",
})
export class ConfigService {
    config: Config | null = null;

    setConfig(config: Config) {
        this.config = config;
    }

    getConfig(): Config | null {
        return this.config;
    }
}
