import { Component, OnInit, ViewChild } from "@angular/core";
import { UserData } from "src/app/dto";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { forkJoin } from "rxjs";
import { BASE_PATH } from "src/app/service/http-abstract-service";

@Component({
    selector: "app-user-table",
    templateUrl: "./user-table.component.html",
    styleUrls: ["./user-table.component.scss"],
})
export class UserTableComponent implements OnInit {
    @ViewChild(MatPaginator) paginator!: MatPaginator;
    allUsers: UserData[] = [];
    paginatedUsers: UserData[] = [];
    selectedUser: UserData | null = null;

    loading: boolean = false;
    imagesData: Array<string> = [];

    totalItems: number = 0;
    totalPages: number = 0;
    pageSize: number = 10;
    currentPage: number = 0;

    constructor(private facetecService: HttpFacetecService) {}

    ngOnInit() {
        this.loadUsers();
    }

    loadUsers() {
        this.facetecService.getUsers().subscribe((data: any) => {
            this.allUsers = data.data.reverse();
            this.totalItems = data.data.length;
            this.totalPages = Math.ceil(this.totalItems / this.pageSize);
            this.updatePaginatedUsers();
        });
    }

    updatePaginatedUsers() {
        const startIndex = this.currentPage * this.pageSize;
        const endIndex = startIndex + this.pageSize;
        this.paginatedUsers = this.allUsers.slice(startIndex, endIndex);
    }

    onRowClick(user: UserData) {
        this.selectedUser = user;
        this.loading = false;
        const imagesUrls = user.uploadDocuments.map((doc) => {
            return `${BASE_PATH}/image/${user.transactionId}/${doc.id}`;
        })

        console.log("Images requests: ", imagesUrls);
        this.imagesData = imagesUrls;
    }

    onPageChange(page: number) {
        if (page >= 0 && page < this.totalPages) {
            this.currentPage = page;
            this.updatePaginatedUsers();
        }
    }

    getPages(): number[] {
        const totalPages = Math.ceil(this.totalItems / this.pageSize);
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }
}
