import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";

@Component({
    selector: "app-terms-and-conditions",
    templateUrl: "./terms-and-conditions.component.html",
    styleUrls: ["./terms-and-conditions.component.scss"],
})
export class TermsAndConditionsComponent implements OnInit {
    display: SafeHtml[] = [];
    private langChangeSubscription: Subscription;

    constructor(
        private router: Router,
        private translateService: TranslateService,
        private sanitized: DomSanitizer,
    ) {
        console.log("TermsAndConditionsComponent constructor");
        this.getDisplayedTerms();
    }

    ngOnInit(): void {
        this.langChangeSubscription = this.translateService.onLangChange.subscribe(
            (event: LangChangeEvent) => {
                this.getDisplayedTerms();
            },
        );
    }

    getDisplayedTerms() {
        this.translateService.get("terms.and.conditions.text").subscribe((res: string) => {
            const termsAndCondition = res;
            const keys = Object.keys(termsAndCondition);
            const displayTermsAndConditions = [];
            for (let key of keys) {
                const value = termsAndCondition[key];
                if (typeof value === "string") {
                    displayTermsAndConditions.push(`<b>${key}</b><br />${value}`);
                } else {
                    displayTermsAndConditions.push(`<b>${key}</b><br />${value.description}}`);

                    const subKeys = Object.keys(value.list);
                    if (subKeys.length > 0) {
                        displayTermsAndConditions.push(`<ul>`);
                        for (let subKey of subKeys) {
                            displayTermsAndConditions.push(
                                `<li style="padding-left: 1.5rem;"><b>${subKey}</b>: ${value.list[subKey]}</li>`,
                            );
                        }
                        displayTermsAndConditions.push(`</ul>`);
                    }
                }
            }
            this.display = displayTermsAndConditions.map((str) =>
                this.sanitized.bypassSecurityTrustHtml(str),
            );
        });
    }

    ngOnDestroy() {
        // Unsubscribe to prevent memory leaks
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    continue() {
        this.router.navigate(["/consent"]);
    }
}
