import { Config } from "../../Config";
import { LivenessCheckProcessor } from "./processors/LivenessCheckProcessor";
import { EnrollmentProcessor } from "./processors/EnrollmentProcessor";
import { VerificationProcessor } from "./processors/VerificationProcessor";
import { SampleAppUtilities } from "./utilities/SampleAppUtilities";
import { PhotoIDMatchProcessor } from "./processors/PhotoIDMatchProcessor";
import { PhotoIDScanProcessor } from "./processors/PhotoIDScanProcessor";
import { ThemeHelpers } from "./utilities/ThemeHelpers";
import { FaceTecSessionResult, FaceTecIDScanResult } from "../../core-sdk/FaceTecSDK.js/FaceTecPublicApi";
import { ClearLatestEnrollmentIdentifier, GetLatestEnrollmentIdentifier, OnComplete } from "./sampleAppControllerReference/SampleAppControllerReference";
import { EventEmitter } from "@angular/core";
import { AdditionalScreens } from "./utilities/AdditionalScreens";

export var SampleApp = ((): any => {
	console.log('SampleApp::constructor');
	var latestEnrollmentIdentifier = "";
	var latestProcessor: LivenessCheckProcessor | EnrollmentProcessor | VerificationProcessor | PhotoIDMatchProcessor | PhotoIDScanProcessor;
	var latestSessionResult: FaceTecSessionResult | null = null;
	var latestIDScanResult: FaceTecIDScanResult | null = null;

	// Clear previous session results;
	function initializeResultObjects(): void {
		latestSessionResult = null;
		latestIDScanResult = null;
	}

	// Initiate a 3D Liveness Check.
	function onLivenessCheckPressed(): void {
		initializeResultObjects();
		SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

		// Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.
		getSessionToken((sessionToken?: string): void => {
			latestProcessor = new LivenessCheckProcessor(sessionToken as string, SampleApp as any);
		});
	}

	// Initiate a 3D Liveness Check, then storing the 3D FaceMap in the Database, also known as "Enrollment".  A random enrollmentIdentifier is generated each time to guarantee uniqueness.
	function onEnrollUserPressed(): void {
		initializeResultObjects();
		SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

		// Get a Session Token from the FaceTec SDK, then start the Enrollment.
		getSessionToken((sessionToken?: string) => {
			latestEnrollmentIdentifier = "browser_sample_app_" + SampleAppUtilities.generateUUId();
			latestProcessor = new EnrollmentProcessor(sessionToken as string, SampleApp as any);
		});
	}

	// Perform 3D to 3D Verification against the Enrollment previously performed.
	function onVerifyUserPressed(): void {
		initializeResultObjects();

		// For demonstration purposes, verify that we have an enrollmentIdentifier to Verify against.
		if (latestEnrollmentIdentifier.length === 0) {
		}
		else {
			SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

			// Get a Session Token from the FaceTec SDK, then start the 3D to 3D Matching.
			getSessionToken((sessionToken?: string): void => {
				latestProcessor = new VerificationProcessor(sessionToken as string, SampleApp as any);
			});
		}
	}

	// Perform a 3D Liveness Check, then an ID Scan, then Match the 3D FaceMap to the ID Scan.
	function onPhotoIDMatchPressed(onSessionToken: EventEmitter<any>, onPortraitResultData: EventEmitter<any>, onIdResultData: EventEmitter<any>): void {
		initializeResultObjects();
		SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

		// Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
		getSessionToken((sessionToken?: string): void => {
			console.log("Dickie: We got session token: ", sessionToken);
			latestEnrollmentIdentifier = "browser_sample_app_" + SampleAppUtilities.generateUUId();
			onSessionToken.emit(sessionToken);
			latestProcessor = new PhotoIDMatchProcessor(sessionToken as string, SampleApp as any, onPortraitResultData as EventEmitter<any>, onIdResultData as EventEmitter<any>);
		});
	}

	// Perform Photo ID Scan, generating a username each time to guarantee uniqueness.
	function onPhotoIDScanPressed(onResultData: EventEmitter<any>): void {
		initializeResultObjects();
		SampleAppUtilities.fadeOutMainUIAndPrepareForSession();

		// Get a Session Token from the FaceTec SDK, then start the 3D Liveness Check.  On Success, ID Scanning will start automatically.
		getSessionToken(function (sessionToken?: string) {
			latestProcessor = new PhotoIDScanProcessor(sessionToken as string, SampleApp as any, onResultData as EventEmitter<any>);
		});
	}

	// Show the final result with the Session Review Screen.
	var onComplete: OnComplete;

	onComplete = (sessionResult: FaceTecSessionResult | null, idScanResult: FaceTecIDScanResult | null, latestNetworkResponseStatus: number): void => {
		latestSessionResult = sessionResult;
		latestIDScanResult = idScanResult;

		if (!latestProcessor.isSuccess()) {
			// Check for server offline
			if (isNetworkResponseServerIsOffline(latestNetworkResponseStatus) === true) {
				showAdditionalScreensServerIsDown();
				return;
			}
		}

		SampleAppUtilities.showMainUI();
	};

	// Check for server down status
	function isNetworkResponseServerIsOffline(networkResponseStatus: number): boolean {
		return (networkResponseStatus >= 500);
	}

	// Set a new customization for FaceTec Browser SDK.
	function onDesignShowcasePressed(): void {
		ThemeHelpers.showNewTheme();
	}

	function onVocalGuidanceSettingsButtonPressed(): void {
		SampleAppUtilities.setVocalGuidanceMode();
	}

	// Display audit trail images captured from user's last FaceTec Browser SDK Session (if available).
	function onViewAuditTrailPressed(): void {
		SampleAppUtilities.showAuditTrailImages(latestSessionResult, latestIDScanResult);
	}

	let sessionTokenErrorHasBeenHandled = false;

	function onSessionTokenError(xhrStatus: number | undefined): void {
		if (sessionTokenErrorHasBeenHandled === false) {
			sessionTokenErrorHasBeenHandled = true;

			if (xhrStatus !== undefined && isNetworkResponseServerIsOffline(xhrStatus)) {
				showAdditionalScreensServerIsDown();
			}
			else {
				onServerSessionTokenError();
			}
		}
	}

	// Get the Session Token from the server
	function getSessionToken(sessionTokenCallback: (sessionToken: string) => void): void {
		sessionTokenErrorHasBeenHandled = false;

		Config.requestNumber += 1;
		try {			
			var XHR = new XMLHttpRequest();
			XHR.open("GET", Config.BaseURL + "/session-token?requestId=" + Config.requestNumber);			
			XHR.setRequestHeader("X-Device-Key", Config.DeviceKeyIdentifier);
			XHR.setRequestHeader("X-User-Agent", FaceTecSDK.createFaceTecAPIUserAgentString(""));
			XHR.setRequestHeader("Authorization", "Bearer " + Config.authToken);			
			XHR.setRequestHeader("Facetec-Tag", Config.facetecTag);

			XHR.onreadystatechange = function (): void {
				if (this.readyState === XMLHttpRequest.DONE) {
					var sessionToken = "";

					try {
						// Attempt to get the sessionToken from the response object.
						sessionToken = JSON.parse(XHR.responseText).sessionToken;

						// Something went wrong in parsing the response. Return an error.
						if (typeof sessionToken !== "string") {
							onSessionTokenError(XHR.status);
							return;
						}
					}
					catch {
						// Something went wrong in parsing the response. Return an error.
						XHR.abort();
						onSessionTokenError(XHR.status);
						return;
					}

					SampleAppUtilities.hideLoadingSessionToken();
					sessionTokenCallback(sessionToken);
				}
			};

			// Wait 3s, if the request is not completed yet, show the session token loading screen
			window.setTimeout(() => {
				if (XHR.readyState !== XMLHttpRequest.DONE) {
					if (sessionTokenErrorHasBeenHandled === false) {
						SampleAppUtilities.showLoadingSessionToken();
					}
				}
			}, 3000);

			XHR.onerror = function (): void {
				XHR.abort();
				onSessionTokenError(XHR.status);
			};

			XHR.send();
		}
		catch (e) {
			onSessionTokenError(undefined);
		}
	}

	function showAdditionalScreensServerIsDown(): void {
		AdditionalScreens.showServerUpGradeView();
	}

	function onServerSessionTokenError(): void {
		SampleAppUtilities.handleErrorGettingServerSessionToken();
	}

	var getLatestEnrollmentIdentifier: GetLatestEnrollmentIdentifier = (): string => {
		return latestEnrollmentIdentifier;
	};

	var clearLatestEnrollmentIdentifier: ClearLatestEnrollmentIdentifier = () => {
		latestEnrollmentIdentifier = "";
	};

	function exitAdditionalScreen(): void {
		AdditionalScreens.exitAdditionalScreen(SampleAppUtilities.showMainUI);
	}

	var FaceTecStrings = {
		"FaceTec_action_ok": "OK",
		"FaceTec_action_im_ready": "I'M READY",
		"FaceTec_action_try_again": "TRY AGAIN",
		"FaceTec_action_continue": "CONTINUE",
		"FaceTec_action_take_photo": "TAKE PHOTO",
		"FaceTec_action_accept_photo": "ACCEPT",
		"FaceTec_action_retake_photo": "RETAKE",
		"FaceTec_action_confirm": "CONFIRM INFO",

		"FaceTec_accessibility_cancel_button": "Cancel",
		"FaceTec_accessibility_tap_guidance": "Double tap on the screen for face alignment guidance",
		"FaceTec_accessibility_key_down_guidance": "Press Enter or Spacebar for face alignment guidance",

		"FaceTec_accessibility_feedback_move_phone_away": "Camera Too Close",
		"FaceTec_accessibility_feedback_move_phone_closer": "Camera Too Far Away",
		"FaceTec_accessibility_feedback_face_too_far_left": "Face Too Far Left",
		"FaceTec_accessibility_feedback_face_too_far_right": "Face Too Far Right",
		"FaceTec_accessibility_feedback_face_too_low": "Face Too Low",
		"FaceTec_accessibility_feedback_face_too_high": "Face Too High",
		"FaceTec_accessibility_feedback_face_rotated_too_far_left": "Face Rotated Too Far Left",
		"FaceTec_accessibility_feedback_face_rotated_too_far_right": "Face Rotated Too Far Right",
		"FaceTec_accessibility_feedback_face_looking_too_far_left": "Face Pointing Too Far Left",
		"FaceTec_accessibility_feedback_face_looking_too_far_right": "Face Pointing Too Far Right",
		"FaceTec_accessibility_feedback_face_not_in_camera": "Face Not On Camera or Too Far Away",
		"FaceTec_accessibility_feedback_hold_phone_to_eye_level": "Hold Device At Eye Level",
		"FaceTec_accessibility_feedback_move_away_web": "Camera Too Close",
		"FaceTec_accessibility_feedback_move_closer_web": "Camera Too Far Away",
		"FaceTec_accessibility_feedback_hold_to_eye_level_web": "Move Camera To Eye Level",

		"FaceTec_presession_frame_your_face": "Frame Your Face In The Oval",
		"FaceTec_presession_look_straight_ahead": "Look Straight Ahead",
		"FaceTec_presession_hold_steady3": "Hold Steady for: 3",
		"FaceTec_presession_hold_steady2": "Hold Steady for: 2",
		"FaceTec_presession_hold_steady1": "Hold Steady for: 1",
		"FaceTec_presession_eyes_straight_ahead": "Look Straight Ahead",
		"FaceTec_presession_remove_dark_glasses": "Remove Dark Glasses",
		"FaceTec_presession_neutral_expression": "Neutral Expression, No Smiling",
		"FaceTec_presession_conditions_too_bright": "Conditions Too Bright",
		"FaceTec_presession_brighten_your_environment": "Brighten Your Environment",

		"FaceTec_feedback_center_face": "Center Your Face",
		"FaceTec_feedback_face_not_found": "Frame Your Face",
		"FaceTec_feedback_move_phone_away": "Move Away",
		"FaceTec_feedback_move_away_web": "Move Away",
		"FaceTec_feedback_move_phone_closer": "Move Closer",
		"FaceTec_feedback_move_web_closer": "Move Face Closer & Fill Oval",
		"FaceTec_feedback_move_web_even_closer": "Even Closer",
		"FaceTec_feedback_move_phone_to_eye_level": "Move Phone To Eye Level",
		"FaceTec_feedback_move_to_eye_level_web": "Look Straight Into Camera",
		"FaceTec_feedback_face_not_looking_straight_ahead": "Look Straight Ahead",
		"FaceTec_feedback_face_not_upright": "Hold Your Head Straight",
		"FaceTec_feedback_face_not_upright_mobile": "Keep Head Straight",
		"FaceTec_feedback_hold_steady": "Hold Steady",
		"FaceTec_feedback_use_even_lighting": "Light Face More Evenly",

		"FaceTec_instructions_header_ready_desktop": "Get Ready For Your Video Selfie",
		"FaceTec_instructions_header_ready_1_mobile": "Get Ready For",
		"FaceTec_instructions_header_ready_2_mobile": "Your Video Selfie",
		"FaceTec_instructions_message_ready_desktop": "Frame Your Face in the Oval, Press I'm Ready & Move Closer",
		"FaceTec_instructions_message_ready_1_mobile": "Frame Your Face in the Oval,",
		"FaceTec_instructions_message_ready_2_mobile": "Press I'm Ready & Move Closer",

		"FaceTec_retry_header": "Let's Try That Again",
		"FaceTec_retry_subheader_message": "We Need a Clearer Video Selfie",
		"FaceTec_retry_your_image_label": "Your Selfie",
		"FaceTec_retry_ideal_image_label": "Ideal Pose",
		"FaceTec_retry_instruction_message_1": "Please Use Ideal Pose & Lighting",
		"FaceTec_retry_instruction_message_2": "Neutral Expression, No Smiling",
		"FaceTec_retry_instruction_message_3": "Too Blurry, Clean Camera",

		"FaceTec_camera_feed_issue_header": "Issue Securing Camera Feed",
		"FaceTec_camera_feed_issue_header_mobile": "Issue Securing<br/>Camera Feed",
		"FaceTec_camera_feed_issue_subheader_message_mobile": "This App blocks suspicious webcam configurations.<br/><a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Learn More Here</a>.",
		"FaceTec_camera_feed_issue_subheader_message": "This system cannot be verified due to the following:",
		"FaceTec_camera_feed_issue_table_header_1": "Possible Issue",
		"FaceTec_camera_feed_issue_table_header_2": "Fix",
		"FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error": "Camera permissions not remembered in Firefox.",
		"FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error": "Check Remember Permissions.",
		"FaceTec_camera_feed_issue_table_row_1_cell_1": "Camera already in use by another App.",
		"FaceTec_camera_feed_issue_table_row_1_cell_2": "Close the other App.",
		"FaceTec_camera_feed_issue_table_row_2_cell_1": "A 3rd-Party App is modifying the video.",
		"FaceTec_camera_feed_issue_table_row_2_cell_2": "Close/Uninstall the other App.",
		"FaceTec_camera_feed_issue_table_row_3_cell_1": "Hardware not capable of being secured.",
		"FaceTec_camera_feed_issue_table_row_3_cell_2": "Use a different Device.",
		"FaceTec_camera_feed_issue_subtable_message": "This App blocks suspicious webcam configurations. <a href='https://livenesscheckhelp.com/' target='_blank' style='text-decoration:underline; font:inherit; pointer-events:all;'>Learn More Here</a>.",
		"FaceTec_camera_feed_issue_action": "TRY AGAIN ANYWAY",
		"FaceTec_camera_feed_issue_action_firefox_permissions_error": "OK",

		"FaceTec_camera_permission_header": "Enable Camera",
		"FaceTec_camera_permission_message": "Camera permissions disabled.<br/>Please check your operating system and browser settings.",

		"FaceTec_enter_fullscreen_header": "Full Screen Selfie Mode",
		"FaceTec_enter_fullscreen_message": "Before we begin, please click the button below to open full screen mode.",
		"FaceTec_enter_fullscreen_action": "OPEN FULL SCREEN",

		"FaceTec_initializing_camera": "Securing Camera Feed",

		"FaceTec_idscan_type_selection_header": "Step 2/3<br/>Scan your Document",
		"FaceTec_idscan_capture_id_front_instruction_message": "Show Front of ID",
		"FaceTec_idscan_capture_id_back_instruction_message": "Show Back of ID",
		"FaceTec_idscan_review_id_front_instruction_message": "Confirm Photo is Clear & Legible",
		"FaceTec_idscan_review_id_back_instruction_message": "Confirm Photo is Clear & Legible",
		"FaceTec_idscan_additional_review_message": "Additional Review<br/>Required",
		"FaceTec_idscan_ocr_confirmation_main_header": "Review & Confirm",
		"FaceTec_idscan_ocr_confirmation_scroll_message": "Scroll Down",

		"FaceTec_result_success_message": "Success!",
		"FaceTec_result_facescan_upload_message": "Uploading<br/>Encrypted<br/>3D FaceScan",
		"FaceTec_result_idscan_upload_message": "Uploading<br/>Encrypted<br/>ID Document",
		"FaceTec_result_idscan_unsuccess_message": "ID Photo<br/>Did Not Match<br/>User's Face",
		"FaceTec_result_idscan_success_front_side_message": "ID Scan Complete",
		"FaceTec_result_idscan_success_front_side_back_next_message": "Front of ID<br/>Scanned",
		"FaceTec_result_idscan_success_back_side_message": "ID Scan Complete",
		"FaceTec_result_idscan_success_passport_message": "Passport Scan Complete",
		"FaceTec_result_idscan_success_user_confirmation_message": "Photo ID Scan<br/>Complete",
		"FaceTec_result_idscan_success_additional_review_message": "ID Photo Capture<br/>Complete",
		"FaceTec_result_idscan_retry_face_did_not_match_message": "Face Didn't Match<br/>Highly Enough",
		"FaceTec_result_idscan_retry_id_not_fully_visible_message": "ID Document<br/>Not Fully Visible",
		"FaceTec_result_idscan_retry_ocr_results_not_good_enough_message": "ID Text Not Legible",
		"FaceTec_result_idscan_retry_id_type_not_suppported_message": "ID Type Not Supported<br/>Please Use a Different ID",
		"FaceTec_result_idscan_retry_barcode_not_read_message": "Barcode Failed To Scan<br/>Please Try Again",
	};

	return {
		onLivenessCheckPressed,
		onEnrollUserPressed,
		onVerifyUserPressed,
		onPhotoIDMatchPressed,
		onPhotoIDScanPressed,
		onDesignShowcasePressed,
		onComplete,
		getLatestEnrollmentIdentifier,
		clearLatestEnrollmentIdentifier,
		onVocalGuidanceSettingsButtonPressed,
		onViewAuditTrailPressed,
		latestSessionResult,
		latestIDScanResult,
	};
})();
