export interface ServerConfig {
    allowedIdTypes: string[];
    browserSdkLicense: string;
    deviceKeyIdentifier: string;
    productionKeyText: string;
    publicFaceScanEncryptionKey: string;
    transactionId: string;
    token: string;
    url: string;
}

export enum ImageTypeEnum {
    Portrait,
    IdPhotoFront,
    IdPhotoBack,
    IdPhotoFace,
    IdSignature,
    Signature,
    TamperingFrontEvidence,
    TamperingBackEvidence,
}

export const imageEnumNameMap = {
    [ImageTypeEnum.Portrait]: "Portrait",
    [ImageTypeEnum.IdPhotoFront]: "IdPhotoFront",
    [ImageTypeEnum.IdPhotoBack]: "IdPhotoBack",
    [ImageTypeEnum.IdPhotoFace]: "IdPhotoFace",
    [ImageTypeEnum.IdSignature]: "IdSignature",
    [ImageTypeEnum.Signature]: "Signature",
    [ImageTypeEnum.TamperingFrontEvidence]: "TamperingFrontEvidence",
    [ImageTypeEnum.TamperingBackEvidence]: "TamperingBackEvidence",
};

export enum ErrorType {
    FaceLivenessFailed, //Not a live face
    OcrTemplateMatchFailed, // ID does not have a corresponding template
    FaceDidNotMatchId, //Portrait and face on ID do not match
    IdTypeNotAllowed, //Id Type blocked based on Axon's server config's allowed ID Types
    IdTypeUnexpectedMedia, //Not a "live" ID Card i.e possibly a picture of a picture (moire lines)
    IdScanFailed,
}

export interface Error {
    errorType: ErrorType;
    data: Data;
    submitted?: boolean;
}

export interface Image {
    image: any;
    type: ImageTypeEnum;
}

export interface Data {
    ref?: string;
    sessionId: string;
    firstName: string;
    lastName: string;
    fullName: string;
    nationality: string;
    nationalityNonMRZValue: string;
    countryCode: string;
    countryCodeNonMRZValue: string;
    dateOfBirth: string;
    placeOfBirth: string;
    idNumber: string;
    capNumber: string;
    country: string;
    province: string;
    municipality: string;
    commune: string;
    phoneNumber: string;
    email: string;
    jobDescription: string;
    idBarcode: string;
    idGroupName: string;
    dateOfIssue: string;
    dateOfExpiration: string;
    sex: string; //yes please
    address: string;
    imageList: Image[];
    userConfirmedValues: any;
    barcodeData: any; //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is complete
    barcodeTemplateName: string;
    barcodeTemplateType: string;
    ocrData: any; //will be a json string of the ocrData as received from FaceTec - we should remove this after testing is complete
    ocrTemplateName: string;
    ocrTemplateType: string;
    idPhotoMatchLevel: number;
    ageEstimate: number;
    browser: string; //userAgent
    deviceModel: string; //deviceModel
    deviceSDK: string; //deviceSDK
    platform: string; //platform
    ipAddress: string;
}

export interface SubmitData {
    referenceId: string;
    firstName: string;
    lastName: string;
    idNumber: string;
    capNumber: string;
    province: string;
    municipality: string;
    commune: string;
    phoneNumber: string;
    email: string;
    country: string;
    jobDescription: string;
    uploadDocuments: Array<{
        name: string;
        data: string;
    }>;
}

export interface UserData extends Omit<SubmitData, "uploadDocuments"> {
    uploadDocuments: Array<{
        name: string;
        id: number;
    }>;
    transactionId: string;
}
