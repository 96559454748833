import { Component, EventEmitter, Output } from "@angular/core";
import { HttpDashboardService } from 'src/app/service/http-dashboard.service';
import { OutputType } from "../../textfield/custom-textfield.component";
import { DashboardUserService } from "src/app/service/dashboard.user.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent {
  newPasswordIsValid: boolean = true;

  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  isLoading: boolean = false;
  constructor(
    private translateService: TranslateService,
    private toastr: ToastrService,
    private httpDashboardService: HttpDashboardService,
    private dashboardUserService: DashboardUserService) { }

  onSubmit() {
    if (this.newPassword.length < 3) {
      this.toastr.error(this.translateService.instant("new.password.invalid"));
      return;
    }

    if (this.newPasswordIsValid) {
      this.isLoading = true;
      this.httpDashboardService.changePassword({ username: this.dashboardUserService.getUser(), password: this.oldPassword, newPassword: this.newPassword }).subscribe((response: any) => {
        console.log(response);
        if (response.username === this.dashboardUserService.getUser()) {
          this.toastr.error(this.translateService.instant("password.change.success"));
          this.dashboardUserService.setChangePasswordScreen(false);
        } else {
          this.toastr.error(response.message);
        }
        this.isLoading = false;
      }, (error) => {
        console.error(error);
        this.isLoading = false;
        this.toastr.error("Change Password Failed: Invalid username or password");
      });
    }
  }

  handleOldPasswordChange(event: OutputType) {
    this.oldPassword = event.value;
  }

  handleNewPasswordChange(event: OutputType) {
    this.newPassword = event.value;
    this.newPasswordIsValid = this.confirmNewPassword === this.newPassword;
  }

  handleConfirmNewPasswordChange(event: OutputType) {
    this.confirmNewPassword = event.value;
    this.newPasswordIsValid = this.confirmNewPassword === this.newPassword;
  }

  cancel() {
    this.dashboardUserService.setChangePasswordScreen(false);
  }

}
