import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class DashboardUserService {
    user: string | null = null;

    setUser(user: string) {
        this.user = user;
    }

    getUser(): string | null {
        return this.user;
    }
}
