import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AbstractHttpService } from "./http-abstract-service";
import { SubmitData } from "../dto";
import { Config as FacetecConfig } from "src/assets/Config";

@Injectable({
    providedIn: "root",
})
export class HttpDashboardService extends AbstractHttpService {
    constructor(http: HttpClient) {
        super(http, "/api/dashboard");
    }

    public getUsers(filter: String, currentPage: number, pageSize: number) {
        const path = `/admin/getAll/${filter}/${currentPage}/${pageSize}`;
        return super.get(path);
    }

    public login({ username, password }: { username: string, password: string }) {
        const path = "/admin/login";
        const data = {
            username,
            password,
        };
        return super.post(path, data);
    }

    public changePassword({ username, password, newPassword }: { username: string, password: string, newPassword: string }) {
        const path = "/admin/changePassword";
        const data = {
            username,
            password,
            newPassword,
        };
        return super.post(path, data);
    }
}
