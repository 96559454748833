<div class="container-fluid d-flex justify-content-center align-items-center w-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>
		<div class="card-body w-100">
			<div class="d-flex flex-column justify-content-between align-items-center custom-card-height">
				<div class="mt-4 col-sm-12 col-md-4">
					<span class="text-bold">{{'successMessage' | translate}}</span>

					<div class="d-flex align-items-center justify-content-center" style="min-height: 75vh">
						<div class="bg-white p-0 rounded-circle">
							<span><fa-icon [icon]="checkIcon" class="text-success"
									style="font-size: 8rem;"></fa-icon></span>
						</div>
					</div>

				</div>

				<div class="text-center mt-auto mb-3 col-sm=12 col-md-4">
					<button type="button" class="btn btn-angola" (click)="restart()">{{'restart' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>