import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
import { AngolaAdminDivisions } from "./angola.lov.admin.divisions";

@Injectable({
  providedIn: 'root'
})

export class AngolaLovDataLoaderService {
  constructor(private http: HttpClient) {}

  loadData(): Observable<AngolaAdminDivisions> {
    const angolaData$ = this.http.get('assets/data/angola-provinces.json');
    const countriesData$ = this.http.get('assets/data/countries.json');

    return forkJoin([angolaData$, countriesData$]).pipe(
      map(([angolaData, countriesData]) => 
        new AngolaAdminDivisions(JSON.stringify(angolaData), JSON.stringify(countriesData))
      )
    );
  }
}