import { Component, EventEmitter, Output } from "@angular/core";
import { HttpDashboardService } from 'src/app/service/http-dashboard.service';
import { OutputType } from "../../textfield/custom-textfield.component";

@Component({
    selector: "app-dashboard-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class DashboardLoginComponent {
    @Output() handleSuccess = new EventEmitter<string>();
    @Output() handleError = new EventEmitter<string>();

    username: string = "";
    password: string = "";
    usernameIsValid: boolean = false;
    passwordIsValid: boolean = true;

    isLoading: boolean = false;
    constructor(private httpDashboardService: HttpDashboardService) { }

    onSubmit() {
        if (this.usernameIsValid && this.passwordIsValid) {
            this.isLoading = true;
            this.httpDashboardService.login({ username: this.username, password: this.password }).subscribe((response: any) => {
                console.log(response);
                if (response.username === this.username) {
                    this.handleSuccess.emit(this.username);
                } else {
                    this.handleError.emit(response.message);
                }
                this.isLoading = false;
            }, (error) => {
                console.error(error);
                this.isLoading = false;
                this.handleError.emit("Login Failed: Invalid username or password");
            });
        }
    }

    handleUsernameChange(event: OutputType) {
        this.usernameIsValid = event.value.length >= 5;
        this.username = event.value;
    }

    handlePasswordChange(event: OutputType) {
        this.password = event.value;
        this.passwordIsValid = this.password.length >= 3;
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        // this.passwordIsValid = passwordRegex.test(this.password);
    }
}
