import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class DashboardUserService {
    private storageKey = 'dashboardUserKey';
    user: string | null = null;
    changePasswordScreen: boolean = false;

    private userSubject = new BehaviorSubject<string | null>(this.getUser());
    private changePasswordScreenSubject = new BehaviorSubject<boolean>(this.changePasswordScreen);

    user$ = this.userSubject.asObservable();
    changePasswordScreen$ = this.changePasswordScreenSubject.asObservable();

    setUser(user: string) {
        sessionStorage.setItem(this.storageKey, user);
        this.userSubject.next(user);        
    }

    getUser(): string | null {
        return sessionStorage.getItem(this.storageKey);
    }

    getChangePasswordScreen(): boolean {
        return this.changePasswordScreen;
    }

    setChangePasswordScreen(value: boolean) {
        this.changePasswordScreen = value;
        this.changePasswordScreenSubject.next(value);
    }
}
