import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
})

export class HeaderComponent {
    xIcon = faXmark;    
    constructor(private router: Router) {

    }
    ngOnInit() {}

    onClose() {
        this.router.navigate([""]);    
    }    
}