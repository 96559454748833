import { Injectable } from "@angular/core";
import { Data, Member } from "../dto";

@Injectable({
    providedIn: "root",
})
export class DataService {
    data: Data | null = null;
    member: Member | null = null;

    setMember(member: Member) {
        this.member = member;
    }

    getMember(): Member | null {
        return this.member;
    }

    setData(data: Data) {
        this.data = data;
    }

    getData(): Data | null {
        return this.data;
    }
}
