<div class="container-fluid d-flex justify-content-center align-items-center w-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>
		<div class="card-body w-100">
			<div class="d-flex flex-column justify-content-between align-items-center">
				<div class="mt-4 col-12 col-md-4">
					<h4>{{'terms.and.conditions' | translate}}</h4>
					<div class="row mt-2">
						<div class="col">
							{{'i.agree' | translate}}
						</div>
					</div>
					<div class="mt-4" *ngFor="let term of display" [innerHTML]="term">
					</div>
				</div>


				<div class="text-center mt-4 mb-3 col-sm=12 col-md-4">
					<button type="button" class="btn btn-angola" (click)="continue()">{{'close' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>