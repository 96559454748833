import { ACCEPTABLE_MATCH_LEVEL } from "src/app/constants";
import { Data, ImageTypeEnum } from "src/app/dto";
import { DataService } from "src/app/service/data.service";

export const processBarcodeData = (data: Data) => {
    console.log("processBarcodeData called", data);
    if (!data.barcodeData) {
        return;
    }

    var userInfo = data.barcodeData.scannedValues.groups[0];
    if (userInfo && userInfo.fields) {
        if (!data.firstName) {
            data.firstName = userInfo.fields.filter(
                (field) => field.fieldKey === "firstName",
            )[0]?.value;
        }
        if (!data.lastName) {
            data.lastName = userInfo.fields.filter(
                (field) => field.fieldKey === "lastName",
            )[0]?.value;
        }
        if (!data.fullName) {
            data.fullName = userInfo.fields.filter(
                (field) => field.fieldKey === "fullName",
            )[0]?.value;
        }
        if (!data.nationality) {
            data.nationality = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.value;
            data.nationalityNonMRZValue = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.nonMRZValue;
        }
        if (!data.countryCode) {
            data.countryCode = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
            data.countryCodeNonMRZValue = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.nonMRZValue;
        }
        if (!data.dateOfBirth) {
            var value = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.value;
            var format = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.uiFieldType;
            data.dateOfBirth = extractDate(value, format);
        }
        if (!data.placeOfBirth) {
            data.placeOfBirth = userInfo.fields.filter(
                (field) => field.fieldKey === "placeOfBirth",
            )[0]?.value;
        }
    }

    var idInfo = data.barcodeData.scannedValues.groups[1];
    if (idInfo && idInfo.fields) {
        if (!data.idNumber) {
            data.idNumber = idInfo.fields.filter(
                (field) => field.fieldKey === "idNumber",
            )[0]?.value;
        }
        if (!data.idBarcode) {
            data.idBarcode = idInfo.fields.filter(
                (field) => field.fieldKey === "barcode",
            )[0]?.value;
        }
        if (!data.dateOfIssue) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]
                ?.uiFieldType;
            data.dateOfIssue = extractDate(value, format);
        }
        if (!data.dateOfExpiration) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.uiFieldType;
            data.dateOfExpiration = extractDate(value, format);
        }
        if (!data.countryCode) {
            data.countryCode = idInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
    }

    var secondaryUserInfo = data.barcodeData.scannedValues.groups[2];
    if (secondaryUserInfo && idInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.barcodeTemplateName = data.barcodeData.templateInfo?.templateName;
    data.barcodeTemplateType = data.barcodeData.templateInfo?.templateType;
};

export const processOcrData = (data: Data) => {
    console.log("processOcrData called", data);
    if (!data.ocrData) {
        return;
    }

    var userInfo = data.ocrData.ocrResults.scanned.groups[0];
    if (userInfo && userInfo.fields) {
        if (!data.firstName) {
            data.firstName = userInfo.fields.filter(
                (field) => field.fieldKey === "firstName",
            )[0]?.value;
        }
        if (!data.lastName) {
            data.lastName = userInfo.fields.filter(
                (field) => field.fieldKey === "lastName",
            )[0]?.value;
        }
        if (!data.fullName) {
            data.fullName = userInfo.fields.filter(
                (field) => field.fieldKey === "fullName",
            )[0]?.value;
        }
        if (!data.nationality) {
            data.nationality = userInfo.fields.filter(
                (field) => field.fieldKey === "nationality",
            )[0]?.value;
        }
        if (!data.countryCode) {
            data.countryCode = userInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
        if (!data.dateOfBirth) {
            var value = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.value;
            var format = userInfo.fields.filter((field) => field.fieldKey === "dateOfBirth")[0]
                ?.uiFieldType;
            data.dateOfBirth = extractDate(value, format);
        }
        if (!data.placeOfBirth) {
            data.placeOfBirth = userInfo.fields.filter(
                (field) => field.fieldKey === "placeOfBirth",
            )[0]?.value;
        }
    }

    var idInfo = data.ocrData.ocrResults.scanned.groups[1];
    if (idInfo && idInfo.fields) {
        if (!data.idNumber) {
            data.idNumber = idInfo.fields.filter(
                (field) => field.fieldKey === "idNumber",
            )[0]?.value;
        }
        if (!data.dateOfIssue) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfIssue")[0]
                ?.uiFieldType;
            data.dateOfIssue = extractDate(value, format);
        }
        if (!data.dateOfExpiration) {
            var value = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.value;
            var format = idInfo.fields.filter((field) => field.fieldKey === "dateOfExpiration")[0]
                ?.uiFieldType;
            data.dateOfExpiration = extractDate(value, format);
        }
        if (!data.countryCode) {
            data.countryCode = idInfo.fields.filter(
                (field) => field.fieldKey === "countryCode",
            )[0]?.value;
        }
    }

    var addressInfo = data.ocrData.ocrResults.scanned.groups[2];
    if (addressInfo && addressInfo.fields) {
        data.address = addressInfo.fields.filter((field) => field.fieldKey === "address")[0]?.value;
    }

    var secondaryUserInfo = data.ocrData.ocrResults.scanned.groups[3];
    if (secondaryUserInfo && secondaryUserInfo.fields) {
        if (!data.sex) {
            data.sex = secondaryUserInfo.fields.filter(
                (field) => field.fieldKey === "sex",
            )[0]?.value;
        }
    }

    data.ocrTemplateName = data.ocrData.ocrResults?.templateName;
    data.ocrTemplateType = data.ocrData.ocrResults?.templateType;
};

export const setIdGroupName = () => {};

export const findIdGroupName = (templateType: string, templateName: string) => {};

export const extractDate = (value: string, format: string) => {
    if (!format) {
        return value;
    }

    if (format === "dd MMM/MMM yyyy") {
        let indexSlash = value.indexOf("/");
        return value.slice(0, indexSlash) + value.slice(indexSlash + 4);
    }

    return value;
};

export const onIdResult = ({
    result,
    dataService,
    handleErrors,
    handleCompletelyDone,
}: {
    result: any;
    dataService: DataService;
    handleErrors: (result: string) => boolean;
    handleCompletelyDone: () => void;
}) => {
    const data = dataService.getData();

    if (result.didMatchIDScanToOCRTemplate === true) {
        if (result.documentData) {
            const documentData = JSON.parse(result.documentData);
            data.barcodeData = documentData;
            processBarcodeData(data);
        }
        if (result.ocrResults) {
            const ocrResults = JSON.parse(result.ocrResults);
            data.ocrData = ocrResults;
            processOcrData(data);
        }
    }

    var idPhotoFront = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFront)[0];
    if (result.photoIDFrontCrop) {
        if (idPhotoFront) {
            idPhotoFront.image = result.photoIDFrontCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFrontCrop,
                type: ImageTypeEnum.IdPhotoFront,
            });
        }
    }

    var idPhotoBack = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoBack)[0];
    if (result.photoIDBackCrop) {
        if (idPhotoBack) {
            idPhotoBack.image = result.photoIDBackCrop;
        } else {
            data.imageList.push({
                image: result.photoIDBackCrop,
                type: ImageTypeEnum.IdPhotoBack,
            });
        }
    }

    var idPhotoFace = data.imageList.filter((item) => item.type === ImageTypeEnum.IdPhotoFace)[0];
    if (result.photoIDFaceCrop) {
        if (idPhotoFace) {
            idPhotoFace.image = result.photoIDFaceCrop;
        } else {
            data.imageList.push({
                image: result.photoIDFaceCrop,
                type: ImageTypeEnum.IdPhotoFace,
            });
        }
    }

    var idSignature = data.imageList.filter((item) => item.type === ImageTypeEnum.IdSignature)[0];
    if (result.photoIDPrimarySignatureCrop) {
        if (idSignature) {
            idSignature.image = result.photoIDPrimarySignatureCrop;
        } else {
            data.imageList.push({
                image: result.photoIDPrimarySignatureCrop,
                type: ImageTypeEnum.IdSignature,
            });
        }
    }

    var tamperFront = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingFrontEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceFrontImage) {
        if (tamperFront) {
            tamperFront.image = result.photoIDTamperingEvidenceFrontImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceFrontImage,
                type: ImageTypeEnum.TamperingFrontEvidence,
            });
        }
    }

    var tamperBack = data.imageList.filter(
        (item) => item.type === ImageTypeEnum.TamperingBackEvidence,
    )[0];
    if (result.photoIDTamperingEvidenceBackImage) {
        if (tamperBack) {
            tamperBack.image = result.photoIDTamperingEvidenceBackImage;
        } else {
            data.imageList.push({
                image: result.photoIDTamperingEvidenceBackImage,
                type: ImageTypeEnum.TamperingBackEvidence,
            });
        }
    }

    data.idPhotoMatchLevel = result.matchLevel;
    data.ageEstimate = result.idScanAgeEstimateGroupV2EnumInt; //could also be result.idScanAgeV2GroupEnumInt (who the fuck knows)
    if (result.additionalSessionData) {
        data.browser = result.additionalSessionData.userAgent;
        data.deviceModel = result.additionalSessionData.deviceModel;
        data.deviceSDK = result.additionalSessionData.deviceSDKVersion;
        data.platform = result.additionalSessionData.platform;
        data.ipAddress = result.additionalSessionData.ipAddress;
    }

    setIdGroupName();

    dataService.setData(data);
    if(result.matchLevel < ACCEPTABLE_MATCH_LEVEL) {
        handleErrors("face.id.match.failed");
        return;
    }

    if (result.isCompletelyDone === true && result.matchLevel >= ACCEPTABLE_MATCH_LEVEL) {
        handleCompletelyDone();
        return;
    } else {
        handleErrors("face.id.match.failed");
    }
};
