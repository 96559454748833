import { Component, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { faGlobe } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-language-switcher",
    template: `
        <button (click)="toggleLanguage()" class="language-switcher btn" type="button">
            <span><fa-icon [icon]="globeIcon"></fa-icon></span>             
        </button>
    `,
    styles: [
        `
            .language-switcher {
                position: fixed;
                bottom: 20px;
                right: 10px;
                min-width: 50px !important;
                padding: 10px 20px;
                cursor: pointer;
                z-index: 1000;
            }
        `,
    ],
})
export class LanguageSwitcherComponent implements OnInit {
    currentLanguage: string;
    supportedLanguages = ["en", "pt"]; // Add more languages as needed
    globeIcon = faGlobe;
    constructor(private translateService: TranslateService) {}

    ngOnInit() {
        this.currentLanguage = this.translateService.getBrowserLang();
    }

    toggleLanguage() {
        const currentIndex = this.supportedLanguages.indexOf(this.currentLanguage);
        const nextIndex = (currentIndex + 1) % this.supportedLanguages.length;
        this.currentLanguage = this.supportedLanguages[nextIndex];
        this.translateService.use(this.currentLanguage);
    }
}
