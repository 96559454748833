<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div *ngIf="isLoading" class="spinner-grow text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="card-header">
          <h3 class="text-center">{{'login.page' | translate}}</h3>
        </div>
        <div class="card-body">
          <form (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="username">{{'username' | translate}}</label>
              <app-custom-textfield type="text" [placeholder]="'username' | translate" name="username"
                [isValid]="usernameIsValid" (inputChange)="handleUsernameChange($event)">
              </app-custom-textfield>
            </div>
            <div class="form-group">
              <label for="password">{{'password' | translate}}</label>
              <app-custom-textfield type="password" [placeholder]="'password' | translate" name="password"
                [isValid]="passwordIsValid" (inputChange)="handlePasswordChange($event)">
              </app-custom-textfield>
            </div>
            <button type="submit" class="btn btn-angola w-100 mt-3">{{'login' | translate}}</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>