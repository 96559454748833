import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

@Component({
    selector: "app-success",
    templateUrl: "./success.component.html",
    styleUrls: ["./success.component.scss"],
})
export class SuccessComponent implements OnInit {
    checkIcon = faCheckCircle;
    constructor(private router: Router) {}

    ngOnInit(): void {}

    restart() {
        this.router.navigate(["/"]);
    }
}
