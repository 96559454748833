<div class="container-fluid d-flex justify-content-center align-items-center w-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>
		<div class="card-body w-100">
			<div class="d-flex flex-column justify-content-between align-items-center">
				<div class="mt-1 col-12 col-md-4">
					<div class="ps-3 pe-3">
						<div class="row">
							<div class="col text-start text-regular">
								<span>{{'fill.form' | translate}}</span>
							</div>
							<div class="col-1 text-end">
								<img src="assets/images/angola/logo.png" height="28px">
							</div>
							<div class="col-1"></div>
						</div>
					</div>
					<div class="ps-3 pe-3 mt-3">
						<div class="row" *ngFor="let field of controlKeys()">
							<div class="col">
								<ng-container *ngIf="
								controls[field].type=='text' || 
								controls[field].type=='number' || 
								controls[field].type=='email'">
									<app-custom-textfield [type]="controls[field].type"
										[placeholder]="controls[field].name | translate" [name]="controls[field].name"
										[initialValue]="controls[field].initialValue"
										[isValid]="controls[field].isValid"
										[disabled]="controls[field].disabled"
										(inputChange)="controls[field].handleChange($event)">
									</app-custom-textfield>
								</ng-container>
								<ng-container *ngIf="controls[field].type=='dropdown'">									
									<div class="searchable-dropdown mb-4" ngbDropdown #myDrop="ngbDropdown">
										<label [for]="controls[field].name">{{controls[field].name | translate}}</label>
										<input type="text" class="form-control" [(ngModel)]="data[field]"
											[id]="controls[field].name"
											(focus)="myDrop.open()" (click)="myDrop.open()"
											[disabled]="controls[field].disabled"
											(ngModelChange)="filterOptions(field)"
											[placeholder]="controls[field].name | translate"
											(click)="$event.stopPropagation()" />
										<div ngbDropdownMenu class="dropdown-menu w-100">
											<button *ngFor="let option of controls[field].options" ngbDropdownItem
												(click)="controls[field].onSelect(option)">
												{{option}}
											</button>
										</div>
									</div>
								</ng-container>
							</div>
						</div>
					</div>
					<div class="ps-3 pe-3 mt-3">
						<div class="row" *ngFor="let image of images">
							<div class="col col-12">
								{{image.name}}
							</div>
							<div class="col col-12">
								<img class="w-100" [src]="image.image">
							</div>
						</div>
					</div>					
				</div>

				<div *ngIf="isLoading" class="spinner-grow text-danger" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>

				<div class="text-center mt-auto mb-3 col-sm-12 col-md-4">
					<button type="button" class="btn btn-angola" (click)="submit()">{{'continue' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>