<div class="container-fluid d-flex justify-content-center align-items-center w-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>

		<div class="card-body w-100" *ngIf="!ref">
			<div class="div mb-3">
				<h5>
					{{'enter.reference' | translate}}
				</h5>
			</div>

			<div class="form-floating mb-2 position-relative">
				<input type="text" placeholder="{{'enter.reference' | translate}}" [(ngModel)]="userRef"
					class="form-control filter-field" name="ref" id="ref" />
				<label [for]="ref">{{'enter.reference' | translate}}</label>
			</div>

			<div class="d-flex justify-content-end">
				<button class="btn btn-angola mt-3" (click)="proceedWithRef()">Proceed</button>
			</div>
		</div>
		<div class="card-body w-100" *ngIf="ref">
			<div class="d-flex flex-column justify-content-between align-items-center custom-card-height">
				<div class="mt-4 col-sm-12 col-md-4">
					<span class="text-bold">{{'hello' | translate}}</span>
					<div class="pt-2 text-regular">
						<span>{{'home.begin.message' | translate}}</span>
					</div>

					<div class="bordered-top mt-4 p-3">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/home/liveness.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start text-regular">
								<span>{{'liveness' | translate}}</span>
							</div>
						</div>
					</div>

					<div class="bordered-top p-3">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/home/idf.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start text-regular">
								<span>{{'id.document.front' | translate}}</span>
							</div>
						</div>
					</div>


					<div class="bordered-top p-3">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/home/idb.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start text-regular">
								<span>{{'id.document.back' | translate}}</span>
							</div>
						</div>
					</div>

					<div class="bordered-top bordered-bottom p-3">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/home/pd.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start text-regular">
								<span>{{'personal.data' | translate}}</span>
							</div>
						</div>
					</div>
				</div>

				<div class="text-center mt-auto mb-3 col-sm=12 col-md-4">
					<button [disabled]="!continuePossible" type="button" class="btn btn-angola"
						(click)="continue()">{{'continue' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>