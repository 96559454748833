import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { RefService } from "src/app/service/ref.service";

@Component({
    selector: "app-no-auth",
    templateUrl: "./no-auth.component.html",
    styleUrls: ["./no-auth.component.scss"],
})
export class NoAuthComponent implements OnInit {
    ref: string = null;
    continuePossible: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private toastr: ToastrService,
        private refService: RefService,
        private translateService: TranslateService,
    ) { }

    randomRef() {
        return Math.random().toString(36).substring(2, 8).toUpperCase();
    }


    ngOnInit(): void {
        this.ref = `no-auth-${this.randomRef()}`;
        this.refService.setRef(this.ref);
        this.continuePossible = true;
    }

    continue() {
        this.router.navigate(["/consent"]);
    }
}
