export interface NameObject {
    firstname: string | null;
    middlename: string | null;
    surname: string | null;
}

export const fuzzySearch = (name1: NameObject, name2: NameObject): number => {
    // Convert names to arrays of non-null values
    console.log("Perfoming fuzzy search on names: ", { name1, name2 });
    const getNameArray = (name: NameObject): string[] => {
        return [name.firstname, name.middlename, name.surname]
            .filter((n) => n !== null && n !== undefined)
            .map(n => n.trim().toUpperCase());
    };

    const names1 = getNameArray(name1);
    const names2 = getNameArray(name2);

    // If either array is empty, return 0
    if (names1.length === 0 || names2.length === 0) {
        return 0;
    }

    // Calculate direct matches and their positions
    const matchScores: number[] = [];

    // Try each possible alignment of names
    for (let i = 0; i < names1.length; i++) {
        for (let j = 0; j < names2.length; j++) {
            const name1 = names1[i];
            const name2 = names2[j];

            // Calculate string similarity
            const similarity = calculateStringSimilarity(name1, name2);
            matchScores.push(similarity);
        }
    }

    // Sort match scores in descending order
    matchScores.sort((a, b) => b - a);

    // Calculate confidence score
    const maxNames = Math.max(names1.length, names2.length);
    const minNames = Math.min(names1.length, names2.length);

    // Take the top N scores where N is the number of names in the shorter array
    const topScores = matchScores.slice(0, minNames);

    // Calculate average of top scores
    const averageScore = topScores.reduce((sum, score) => sum + score, 0) / minNames;

    // Penalize for missing names
    const lengthPenalty = minNames / maxNames;

    // Final confidence is average score multiplied by length penalty
    return Math.round(averageScore * lengthPenalty * 100);
}

function calculateStringSimilarity(str1: string, str2: string): number {
    const longer = str1.length > str2.length ? str1 : str2;
    const shorter = str1.length > str2.length ? str2 : str1;

    // Early return for exact matches
    if (str1 === str2) return 1.0;

    // Early return if one string is empty
    if (shorter.length === 0) return 0.0;

    // Calculate Levenshtein distance
    const distance = levenshteinDistance(str1, str2);

    // Convert distance to similarity score
    return (longer.length - distance) / longer.length;
}

function levenshteinDistance(str1: string, str2: string): number {
    const matrix: number[][] = [];

    // Initialize matrix
    for (let i = 0; i <= str1.length; i++) {
        matrix[i] = [i];
    }
    for (let j = 0; j <= str2.length; j++) {
        matrix[0][j] = j;
    }

    // Fill matrix
    for (let i = 1; i <= str1.length; i++) {
        for (let j = 1; j <= str2.length; j++) {
            if (str1[i - 1] === str2[j - 1]) {
                matrix[i][j] = matrix[i - 1][j - 1];
            } else {
                matrix[i][j] = Math.min(
                    matrix[i - 1][j - 1] + 1, // substitution
                    matrix[i][j - 1] + 1,     // insertion
                    matrix[i - 1][j] + 1      // deletion
                );
            }
        }
    }

    return matrix[str1.length][str2.length];
}

// Example usage:
const name1: NameObject = {
    firstname: "AKINTAYO",
    middlename: "ABIONA",
    surname: "OLUSEGUN"
};

const name2: NameObject = {
    firstname: "OLUSEGUN",
    middlename: "AKINTAYO",
    surname: "ABIONA"
};

const name3: NameObject = {
    firstname: "ABDUL",
    middlename: null,
    surname: "AFEEZ"
};

const name4: NameObject = {
    firstname: "ABDULAFEEZ",
    middlename: "HAMMED",
    surname: "MUHAMMED"
};

console.log(fuzzySearch(name1, name2)); // Should output close to 100
console.log(fuzzySearch(name3, name4)); // Should output a high but lower score