import { Component, EventEmitter, Input, Output } from "@angular/core";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

export interface OutputType {
    name: string;
    value: string;
}
@Component({
    selector: "app-custom-textfield",
    templateUrl: "./custom-textfield.component.html",
    styleUrls: ["./custom-textfield.component.scss"],
})
export class CustomTextFieldComponent {
    @Input() placeholder: string = "";
    @Input() initialValue: string = "";
    @Input() name: string = "";
    @Input() isValid: boolean = false;
    @Input() type: string = "text";
    @Output() inputChange = new EventEmitter<OutputType>();

    faCheckCircle = faCheckCircle;

    ngOnInit() {
        this.emitOutput();
    }

    emitOutput() {
        this.inputChange.emit({
            name: this.name,
            value: this.initialValue,
        });
    }
}
