import { fuzzySearch, NameObject } from "./fuzzy";

export const formatString = (template: string, values: (string | number)[]): string => {
    return template.replace(/{(\d+)}/g, (match, index) => {
        const value = values[Number(index)];
        return value !== undefined ? value.toString() : match; // Replace if value exists, otherwise keep the placeholder
    });
}

export const compareNames = (obj1: NameObject, obj2: NameObject): number => {
    return fuzzySearch(obj1, obj2);    
}