<div class="container-fluid">
  <div class="row">
    <div class="col-md-9 d-flex flex-column">
      <div class="table-container flex-grow-1">
        <div class="filters">
          <button class="btn btn-angola mt-3 filter-button"
            [ngClass]="{ 'active-filter': currentFilter === 'registered' }" [disabled]="currentFilter === 'registered'"
            (click)="filterBy('registered')">Registered</button>
          <button class="btn btn-angola mt-3 filter-button" [ngClass]="{ 'active-filter': currentFilter === 'recent' }"
            [disabled]="currentFilter === 'recent'" (click)="filterBy('recent')">Recent</button>
          <button class="btn btn-angola mt-3 filter-button" [ngClass]="{ 'active-filter': currentFilter === 'invited' }"
            [disabled]="currentFilter === 'invited'" (click)="filterBy('invited')">Invited</button>
          <button type="submit" class="btn btn-angola filter-button mt-3" (click)="logout()">{{'logout' | translate}}</button>
          <button type="submit" class="btn btn-angola filter-button mt-3" (click)="changePassword()">{{'change.password' | translate}}</button>
        </div>
        <div class="filters">
          <input type="text" placeholder="Search by Name, Surname, ID, Email, Phone" [(ngModel)]="searchQuery"
            (input)="applySearch()" class="form-control filter-field" />
        </div>
        <div class="filters">
          <div class="searchable-dropdown mb-4" ngbDropdown #myDrop="ngbDropdown">
            <label for="pageSizeDiv">{{'rowsPerPage' | translate}}</label>
            <input type="text" class="form-control" id="pageSizeDiv" (focus)="myDrop.open()" (click)="myDrop.open()"
              [(ngModel)]="pageSize" (click)="$event.stopPropagation()" />
            <div ngbDropdownMenu class="dropdown-menu w-100">
              <button *ngFor="let option of rowsPerPageOptions" ngbDropdownItem (click)="setPageSize(option)">
                {{option}}
              </button>
            </div>
          </div>
        </div>

        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>Status</th>
              <th>{{'firstName' | translate}}</th>
              <th>{{'lastName' | translate}}</th>
              <th>{{'phoneNumber' | translate}}</th>
              <th>{{'email' | translate}}</th>
              <th>{{'idNumber' | translate}}</th>
              <th>{{'capNumber' | translate}}</th>
              <th>{{'country' | translate}}</th>
              <th>{{'province' | translate}}</th>
              <th>{{'municipality' | translate}}</th>
              <th>{{'commune' | translate}}</th>
              <th>{{'created' | translate}}</th>
              <th>{{'updated' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of displayUsers" (click)="onRowClick(user)"
              [class.table-active]="user === selectedUser">
              <td>
                <span *ngIf="user.status === 'COMPLETED'" class="green-tick">✔</span>
                <span *ngIf="user.status !== 'COMPLETED'" class="grey-tick">✖</span>
              </td>
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>{{ user.phoneNumber }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.idNumber }}</td>
              <td>{{ user.capNumber }}</td>
              <td>{{ user.country }}</td>
              <td>{{ user.province }}</td>
              <td>{{ user.municipality }}</td>
              <td>{{ user.commune }}</td>
              <td>{{ user.createdAt }}</td>
              <td>{{ user.updatedAt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 0">
            <a class="page-link" href="#" (click)="onPageChange(currentPage - 1); $event.preventDefault()">Previous</a>
          </li>
          <li class="page-item">
            <a class="page-link" href="#">{{currentPage + 1}}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages - 1">
            <a class="page-link" href="#" (click)="onPageChange(currentPage + 1); $event.preventDefault()">Next</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-3" *ngIf="loading">
      <div class="text-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <div class="col-md-3 sticky-card-container" *ngIf="!loading">
      <div *ngIf="selectedUser" class="card sticky-card">
        <div class="card-body">
          <h5 class="card-title">{{ selectedUser.firstName }} {{ selectedUser.lastName }}</h5>
          <p class="card-text">{{'idNumber' | translate}}: <b>{{ selectedUser.idNumber }}</b></p>
          <p class="card-text">{{'phoneNumber' | translate}}: <b>{{ selectedUser.phoneNumber }}</b></p>
          <p class="card-text">{{'email' | translate}}: <b>{{ selectedUser.email }}</b></p>
          <p class="card-text">{{'jobDescription' | translate}}: <b>{{ selectedUser.jobDescription }}</b></p>
          <ng-container *ngIf="selectedUser.member">
            <p class="card-text">{{'sms.send' | translate}}: <b>{{ selectedUser.member.smsed }}</b></p>
            <p class="card-text">{{'link.clicked' | translate}}: <b>{{ selectedUser.member.linkClicked }}</b></p>
          </ng-container>
          <a class="mt-2 fw-bold fs-6" (click)="openSendInvite()">{{'send.invite' | translate}}</a>
          <ng-container *ngIf="sendingInvite">
            <div class="form-floating mb-2 mt-2 position-relative">
              <input type="text" class="form-control" placeholder="{{'phoneNumber' | translate}}"
                [(ngModel)]="phoneNumber" name="phoneNumber" id="phoneNumber">
              <label for="phoneNumber">{{'phoneNumber' | translate}}</label>
            </div>
            <button class="btn btn-angola mt-1" (click)="sendInvite()">{{'send' | translate}}</button>
          </ng-container>
          <div *ngIf="imagesData.length > 0" class="image-scroll-container mt-2">
            <div class="image-scroll-wrapper">
              <div *ngFor="let image of imagesData" class="image-item">
                <img [src]="image">
              </div>
            </div>
          </div>
          <div *ngIf="imagesData.length === 0 && !loading">
            <p>No images available</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>