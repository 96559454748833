import { Routes, RouterModule } from "@angular/router";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { FormsModule } from "@angular/forms";
import { HttpClient, HttpClientModule } from "@angular/common/http";
import { BrowserAnimationsModule, provideAnimations } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { WebcamModule } from "ngx-webcam";
import { ZXingScannerModule } from "@zxing/ngx-scanner";
import type { FaceTecSDK as FaceTecSDKType } from "../assets/core-sdk/FaceTecSDK.js/FaceTecSDK";
import { ToastrModule, provideToastr } from "ngx-toastr";
import { HomeComponent } from "./pages/home/home.component";
import { ConsentComponent } from "./pages/consent/consent.component";
import { CommonModule } from "@angular/common";
import { CustomToggleComponent } from "./components/toggle/custom-toggle.component";
import { HeaderComponent } from "./components/header/header.component";
import { PersonalDataComponent } from "./pages/personal-data/personal-data.component";
import { CustomTextFieldComponent } from "./components/textfield/custom-textfield.component";
import { RefService } from "./service/ref.service";
import { ConfigService } from "./service/config.service";
import { DataService } from "./service/data.service";
import { FacetecSDKService } from "./service/facetec.sdk.service";
import { AngolaLovDataLoaderService } from "./pages/personal-data/services/angola.lov.data.loader.service";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { LanguageSwitcherComponent } from "./components/language-switcher/language-switcher.component";
import { TermsAndConditionsComponent } from "./pages/terms-and-conditions/terms-and-conditions.component";
import { SuccessComponent } from "./pages/success/success.component";
import { DashboardComponent } from "./components/dashboard/dashboard.component";
import { DashboardUserService } from "./service/dashboard.user.service";
import { DashboardLoginComponent } from "./components/dashboard/login/login.component";
import { UserTableComponent } from "./components/dashboard/user-table/user-table.component";
import { NoAuthComponent } from "./pages/no-auth/no-auth.component";
import { ChangePasswordComponent } from "./components/dashboard/change-password/change-password.component";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

const routes: Routes = [
    { path: "", component: HomeComponent },
    { path: "home", component: HomeComponent },
    { path: "consent", component: ConsentComponent },
    { path: "personal-data", component: PersonalDataComponent },
    { path: "terms", component: TermsAndConditionsComponent },
    { path: "success", component: SuccessComponent },
    { path: "dashboard", component: DashboardComponent },
    { path: "no-auth/91870", component: NoAuthComponent },
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        ConsentComponent,
        CustomToggleComponent,
        CustomTextFieldComponent,
        HeaderComponent,
        LanguageSwitcherComponent,
        PersonalDataComponent,
        TermsAndConditionsComponent,
        SuccessComponent,
        DashboardComponent,
        DashboardLoginComponent,
        UserTableComponent,
        NoAuthComponent,
        ChangePasswordComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        NgbModule,
        WebcamModule,
        ZXingScannerModule,
        FontAwesomeModule,
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        RouterModule.forRoot(routes, { useHash: false }),
        BrowserAnimationsModule,
        NgxSpinnerModule,
        ToastrModule.forRoot(),
    ],
    exports: [RouterModule, NgxSpinnerModule],
    bootstrap: [AppComponent],
    providers: [
        RefService,
        ConfigService,
        DataService,
        FacetecSDKService,
        DashboardUserService,
        AngolaLovDataLoaderService,
        provideAnimations(), // required animations providers
        provideToastr(), // Toastr providers
    ],
})
export class AppModule { }

declare global {
    const FaceTecSDK: typeof FaceTecSDKType;
}
