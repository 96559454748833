import { Injectable } from "@angular/core";
import { SampleAppUtilities } from "src/assets/FaceTecAngularSampleApp/src/utilities/SampleAppUtilities";
import { ThemeHelpers } from "src/assets/FaceTecAngularSampleApp/src/utilities/ThemeHelpers";
import { Config } from "src/assets/Config";
import { getLocalizedStrings } from "../constants";
import { DeveloperStatusMessages } from "src/assets/FaceTecAngularSampleApp/src/utilities/DeveloperStatusMessages";
import { TranslateService } from "@ngx-translate/core";

@Injectable({
    providedIn: "root",
})
export class FacetecSDKService {
    latestSessionResult = null;
    latestIDScanResult = null;

    constructor(private translateService: TranslateService) {}

    initializeBrowserSDK({
        onInitializeSuccess,
        onInitializeFailed,
    }: {
        onInitializeSuccess: () => void;
        onInitializeFailed: () => void;
    }) {
        console.log("Initialize Called: " + new Date().toISOString());
        SampleAppUtilities.formatUIForDevice();
        // Set a the directory path for other FaceTec Browser SDK Resources.
        FaceTecSDK.setResourceDirectory("/assets/core-sdk/FaceTecSDK.js/resources");
        // Set the directory path for required FaceTec Browser SDK images.
        FaceTecSDK.setImagesDirectory("/assets/core-sdk/FaceTec_images");
        // Set your FaceTec Device SDK Customizations.
        ThemeHelpers.setAppTheme(ThemeHelpers.getCurrentTheme());
        // Initialize FaceTec Browser SDK and configure the UI features.
        const facetecService = this;
        Config.initializeFromAutogeneratedConfig(
            FaceTecSDK,
            function (initializedSuccessfully: boolean) {
                if (initializedSuccessfully) {
                    console.log("initialise success: ", initializedSuccessfully);
                    facetecService.onFaceTecSDKInitializationSuccess();
                    onInitializeSuccess();
                } else {
                    console.log("initialise failed: ", initializedSuccessfully);
                    facetecService.onFaceTecSDKInitializationFailure();
                    onInitializeFailed();
                }
            },
        );

        SampleAppUtilities.fadeInMainUIContainer();
    }

    onFaceTecSDKInitializationSuccess(): void {
        console.log("onFaceTecSDKInitializationSuccess :: 1");
        // Set your FaceTec Device SDK Customizations.
        ThemeHelpers.setAppTheme(ThemeHelpers.getCurrentTheme());
        // Set the sound files that are to be used for Vocal Guidance.
        SampleAppUtilities.setVocalGuidanceSoundFiles();
        const locale = this.translateService.currentLang;
        getLocalizedStrings(locale).then((module) => {
            console.log("onFaceTecSDKInitializationSuccess :: 2", {
                ...module
            });
            const FaceTecStrings = module.FaceTecStrings;
            FaceTecSDK.configureLocalization({
                ...module
            });
            // Set the strings to be used for group names, field names, and placeholder texts for the FaceTec ID Scan User OCR Confirmation Screen.
            SampleAppUtilities.setOCRLocalization();
            DeveloperStatusMessages.logInitializeResult();
    
        });
    }

    onFaceTecSDKInitializationFailure(): void {
        DeveloperStatusMessages.logInitializeResult();
    }
    initializeResultObjects(): void {
        this.latestSessionResult = null;
        this.latestIDScanResult = null;
    }
}
