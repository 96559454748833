import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { OutputType } from "src/app/components/textfield/custom-textfield.component";
import { Data, imageEnumNameMap, Member, SubmitData } from "src/app/dto";
import { AngolaAdminDivisions } from "./services/angola.lov.admin.divisions";
import { ControlsType } from "./services/types";
import { DataService } from "src/app/service/data.service";
import { AngolaLovDataLoaderService } from "./services/angola.lov.data.loader.service";
import { FormControl } from "@angular/forms";
import { Config as FacetecConfig } from "src/assets/Config";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { compareNames, formatString } from "./utils";
import { TranslateService } from "@ngx-translate/core";
import { RefService } from "src/app/service/ref.service";

@Component({
    selector: "app-personal-data",
    templateUrl: "./personal-data.component.html",
    styleUrls: ["./personal-data.component.scss"],
})
export class PersonalDataComponent implements OnInit {
    controls: ControlsType = {} as ControlsType;
    data = {} as Data;
    member = {} as Member;
    lovData: AngolaAdminDivisions;
    searchControl: FormControl = new FormControl("");
    isLoading: boolean = false;

    images = [];

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private dataService: DataService,
        private httpService: HttpFacetecService,
        private translateService: TranslateService,
        private refService: RefService,
        private angolaLovDataLoaderService: AngolaLovDataLoaderService,
    ) {
        console.log("PersonalDataComponent constructor");
    }

    ngOnInit(): void {
        // this.data = {
        //     firstName: "",
        //     lastName: "",
        //     idNumber: "",
        //     capNumber: "",
        //     country: "",
        //     province: "",
        //     municipality: "",
        //     commune: "",
        //     phoneNumber: "",
        //     email: "",
        //     jobDescription: "",
        //     imageList: [],
        //     sessionId: "",
        //     fullName: "",
        //     nationality: "",
        // } as Data;

        this.data = this.dataService.getData();

        // this.member = {
        //     cap: "",
        //     email: "",
        //     mobileNumber: "",
        //     firstName: "",
        //     secondName: "",
        //     surname: "",
        //     id: "",
        // } as Member;

        this.member = this.dataService.getMember();

        this.data.country = "Angola";
        this.data.province = this.member.province;

        this.images = this.data.imageList.map((image) => {
            return {
                name: imageEnumNameMap[image.type],
                image: `data:image/png;base64,${image.image}`,
            };
        });

        this.angolaLovDataLoaderService.loadData().subscribe((data: AngolaAdminDivisions) => {
            const lovData = data;
            console.log("Lov Data: ", lovData);
            this.lovData = lovData;

            this.controls = {
                firstName: {
                    name: "firstName",
                    placeholder: "First Name",
                    disabled: true,
                    initialValue: this.data.firstName || "",
                    isValid: false,
                    type: "text",
                    value: this.data.firstName,
                    handleChange: (event: OutputType) => {
                        this.data.firstName = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                lastName: {
                    name: "lastName",
                    placeholder: "Last Name",
                    initialValue: this.data.lastName || "",
                    isValid: false,
                    disabled: true,
                    type: "text",
                    value: this.data.lastName,
                    handleChange: (event: OutputType) => {
                        this.data.lastName = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                idNumber: {
                    name: "idNumber",
                    placeholder: "ID Number",
                    initialValue: this.data.idNumber || "",
                    isValid: false,
                    disabled: true,
                    type: "text",
                    value: this.data.idNumber,
                    handleChange: (event: OutputType) => {
                        this.data.idNumber = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                capNumber: {
                    name: "capNumber",
                    placeholder: "CAP Number",
                    initialValue: this.data.capNumber || this.member.cap || "000",
                    isValid: false,
                    disabled: true,
                    type: "text",
                    value: this.data.capNumber,
                    handleChange: (event: OutputType) => {
                        this.data.capNumber = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                country: {
                    name: "country",
                    placeholder: "Countries",
                    initialValue: this.data.country || "",
                    isValid: false,
                    type: "dropdown",
                    value: this.data.country,
                    options: lovData.getCountries().map((country) => country.name),
                    onSelect: (option) => {
                        this.data.country = option;
                        this.dataService.setData(this.data);
                        this.data.province = "";
                        this.data.municipality = "";
                        this.data.commune = "";
                        this.controls.province.options =
                            option === "Angola"
                                ? lovData.getProvinces().map((province) => province.name)
                                : [];
                        this.controls.province.disabled = option !== "Angola";
                        this.controls.municipality.disabled = option !== "Angola";
                        this.controls.commune.disabled = option !== "Angola";
                        this.controls.municipality.options = [];
                        this.controls.commune.options = [];
                    },
                },
                province: {
                    name: "province",
                    placeholder: "Province",
                    initialValue: this.data.province || "",
                    isValid: false,
                    type: "dropdown",
                    value: this.data.province,
                    disabled: true,
                    options: [],
                    onSelect: (option) => {
                        this.data.province = option;
                        this.dataService.setData(this.data);
                        this.controls.municipality.options = lovData
                            .getMunicipalities(option)
                            .map((municipality) => municipality.name);
                        this.data.municipality = "";
                        this.data.commune = "";
                        this.controls.commune.options = [];
                    },
                },
                municipality: {
                    name: "municipality",
                    placeholder: "Municipality",
                    initialValue: this.data.municipality || "",
                    isValid: false,
                    type: "dropdown",
                    value: this.data.municipality,
                    disabled: this.data.country !== "Angola",
                    options: [],
                    onSelect: (option) => {
                        this.data.municipality = option;
                        this.dataService.setData(this.data);
                        this.controls.commune.options = lovData
                            .getCommunes(this.data.province, option)
                            .map((commune) => commune.name);
                        this.data.commune = "";
                    },
                },
                commune: {
                    name: "commune",
                    placeholder: "Commune",
                    initialValue: this.data.commune || "",
                    isValid: false,
                    type: "dropdown",
                    value: this.data.commune,
                    disabled: this.data.country !== "Angola",
                    options: [],
                    onSelect: (option) => {
                        this.data.commune = option;
                        this.dataService.setData(this.data);
                    },
                },
                phoneNumber: {
                    name: "phoneNumber",
                    placeholder: "Phone Number",
                    initialValue: this.data.phoneNumber || this.member.mobileNumber || "",
                    isValid: false,
                    type: "number",
                    value: this.data.phoneNumber,
                    handleChange: (event: OutputType) => {
                        this.data.phoneNumber = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                email: {
                    name: "email",
                    placeholder: "Email",
                    initialValue: this.data.email || this.member.email || "",
                    isValid: false,
                    type: "email",
                    value: this.data.email,
                    handleChange: (event: OutputType) => {
                        this.data.email = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
                jobDescription: {
                    name: "jobDescription",
                    placeholder: "Job Description *",
                    initialValue: this.data.jobDescription || "",
                    isValid: false,
                    type: "text",
                    value: this.data.jobDescription,
                    handleChange: (event: OutputType) => {
                        this.data.jobDescription = event.value;
                        this.dataService.setData(this.data);
                        this.validate(event, false);
                    },
                },
            };

            // set municipality and commune options because province is already set
            this.controls.municipality.options = lovData
                .getMunicipalities(this.data.province)
                .map((municipality) => municipality.name);
            this.data.municipality = "";
            this.data.commune = "";
            this.controls.commune.options = [];
        });
    }

    controlKeys() {
        return Object.keys(this.controls);
    }

    handleInputChange(event: OutputType) {
        if (this.validate(event)) {
            // then do something
        }
    }

    containsNumber(str: string): boolean {
        return /\d/.test(str);
    }

    validateEmail(event: OutputType, showError: boolean) {
        const re = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!re.test(event.value)) {
            if (showError) {
                const message = this.translateService.instant("error.not.valid.email");
                const formatted = message.replace("{0}", event.name);
                this.toastr.error(formatted);
            }

            this.controls[event.name].isValid = false;
            return false;
        }

        return true;
    }

    validateLength(event: OutputType, showError: boolean, min: number, max: number) {
        if (event.value.length < min || event.value.length > max) {
            if (showError) {
                const message = this.translateService.instant("error.field.length");
                const formatted = formatString(message, [event.name, min, max]);
                this.toastr.error(formatted);
            }

            this.controls[event.name].isValid = false;
            return false;
        }

        return true;
    }

    validate(event: OutputType, showError = true) {
        if (event.value.length < 0) {
            if (showError) {
                const message = this.translateService.instant("error.field.can.not.be.empty");
                const formatted = message.replace("{0}", event.name);
                this.toastr.error(formatted);
            }

            this.controls[event.name].isValid = false;
            return false;
        }

        switch (event.name) {
            case "firstName":
            case "lastName":
                if (this.validateLength(event, showError, 3, 30)) {
                    if (this.containsNumber(event.value)) {
                        if (showError) {
                            const message = this.translateService.instant("error.field.can.not.contain.numbers");
                            const formatted = message.replace("{0}", event.name);
                            this.toastr.error(formatted);
                        }

                        this.controls[event.name].isValid = false;
                        return false;
                    }
                } else {
                    return false;
                }
                break;
            case "idNumber":
            case "capNumber":
                if (!this.validateLength(event, showError, 1, 30)) {
                    return false;
                }
                break;
            case "phoneNumber":
                if (!this.validateLength(event, showError, 3, 30)) {
                    return false;
                }
                break;
            case "email":
                if (!this.validateEmail(event, showError)) {
                    return false;
                }
                break;
            case "jobDescription":
                if (!this.validateLength(event, showError, 4, 50)) {
                    return false;
                }
                break;
            default:
                return true;
        }

        this.controls[event.name].isValid = true;
        return true;
    }

    filterOptions(field: string) {
        let lovData;
        switch (field) {
            case "country":
                lovData = this.lovData.getCountries();
                break;
            case "province":
                lovData = this.data.country === "Angola" ? this.lovData.getProvinces() : [];
                break;
            case "municipality":
                lovData =
                    this.data.country === "Angola"
                        ? this.lovData.getMunicipalities(this.data.province)
                        : [];
                break;
            case "commune":
                lovData =
                    this.data.country === "Angola"
                        ? this.lovData.getCommunes(this.data.province, this.data.municipality)
                        : [];
                break;
        }

        const filteredOptions = lovData
            .map((data) => data.name)
            .filter((option) => option.toLowerCase().includes(this.data[field].toLowerCase()));

        this.controls[field].options = filteredOptions;
    }

    transformDataToSubmitData(data: Data): SubmitData {
        return {
            referenceId: FacetecConfig.facetecTag,
            firstName: data.firstName,
            lastName: data.lastName,
            idNumber: data.idNumber,
            capNumber: data.capNumber,
            country: data.country,
            province: data.province,
            municipality: data.municipality,
            commune: data.commune,
            phoneNumber: data.phoneNumber,
            email: data.email,
            jobDescription: data.jobDescription,
            uploadDocuments: data.imageList.map((item) => {
                return {
                    name: imageEnumNameMap[item.type],
                    data: item.image,
                };
            }),
        };
    }

    submit() {
        // check if data matches the fields in member object
        const member = this.dataService.getMember();
        // const ratio = compareNames({
        //     firstname: member?.firstName,
        //     middlename: member?.secondName,
        //     surname: member?.surname,
        // }, {
        //     firstname: this.data.firstName,
        //     middlename: this.data.lastName,
        //     surname: this.data.lastName,
        // });

        // console.log("Fuzzy Match Ratio: ", ratio);

        // if (ratio < 50) {
        //     const errorMessage = this.translateService.instant("error.data.mistmatch");
        //     const error = this.translateService.instant("error");
        //     this.toastr.error(errorMessage, error);
        //     return;
        // }

        this.isLoading = true;
        this.dataService.setData(this.data);

        this.data.capNumber = this.data.capNumber || member.cap || "000";
        this.data.phoneNumber = this.data.phoneNumber || member.mobileNumber || "";
        this.data.email = this.data.email || member.email || "";

        console.log("Data to submit: ", this.data);
        try {
            this.httpService.submit(this.refService.getRef(), this.transformDataToSubmitData(this.data)).subscribe(
                (data: any) => {
                    if (data.code === 200) {
                        const message = this.translateService.instant("data.submitted.successfully");
                        const success = this.translateService.instant("success");
                        this.toastr.success(message, success);
                        setTimeout(() => {
                            this.router.navigate(["/success"]);
                            this.isLoading = false;
                        }, 1500);
                    } else {
                        this.toastr.error(data.message, this.translateService.instant("error"));
                        this.isLoading = false;
                    }
                },
                (error) => {
                    this.toastr.error(error.message, this.translateService.instant("error"));
                    this.isLoading = false;
                },
            );
        } catch (error) {
            this.toastr.error(error.message, this.translateService.instant("error"));
            this.isLoading = false;
        }
    }
}
