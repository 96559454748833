import { Component, EventEmitter, Output } from "@angular/core";
import { OutputType } from "../../textfield/custom-textfield.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-dashboard-login",
    templateUrl: "./login.component.html",
    styleUrls: ["./login.component.scss"],
})
export class DashboardLoginComponent {
    @Output() handleSuccess = new EventEmitter<string>();
    @Output() handleError = new EventEmitter<string>();

    username: string = "";
    password: string = "";
    usernameIsValid: boolean = false;
    passwordIsValid: boolean = true;

    validUsernames = ["admin", "mpla_user"];
    validPasswords = ["axon@face2face", "J8k#v4Lm2"];

    constructor(private translateService: TranslateService) {}

    onSubmit() {
        if (this.usernameIsValid && this.passwordIsValid) {
            if (this.validUsernames.includes(this.username) && this.validPasswords.includes(this.password)) {
                this.handleSuccess.emit(this.username);
                this.usernameIsValid = true;
                this.passwordIsValid = true;

            } else {
                this.translateService.get("invalid.username.password").subscribe((res: string) => {
                    this.handleError.emit(res);
                    this.usernameIsValid = false;
                    this.passwordIsValid = false;
                });
            }
        }
    }

    handleUsernameChange(event: OutputType) {
        this.usernameIsValid = event.value.length >= 5;
        this.username = event.value;
    }

    handlePasswordChange(event: OutputType) {
        this.password = event.value;
        this.passwordIsValid = true;
        // const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        // this.passwordIsValid = passwordRegex.test(this.password);
    }
}
