<div class="container-fluid h-100">
  <div class="row h-100">
    <div class="col-md-9 d-flex flex-column">
      <div class="table-container flex-grow-1">
        <table class="table table-striped table-hover">
          <thead>
            <tr>
              <th>{{'firstName' | translate}}</th>
              <th>{{'lastName' | translate}}</th>
              <th>{{'idNumber' | translate}}</th>
              <th>{{'capNumber' | translate}}</th>
              <th>{{'country' | translate}}</th>
              <th>{{'province' | translate}}</th>
              <th>{{'municipality' | translate}}</th>
              <th>{{'commune' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let user of paginatedUsers" (click)="onRowClick(user)"
              [class.table-active]="user === selectedUser">
              <td>{{ user.firstName }}</td>
              <td>{{ user.lastName }}</td>
              <td>{{ user.idNumber }}</td>
              <td>{{ user.capNumber }}</td>
              <td>{{ user.country }}</td>
              <td>{{ user.province }}</td>
              <td>{{ user.municipality }}</td>
              <td>{{ user.commune }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <nav>
        <ul class="pagination justify-content-center">
          <li class="page-item" [class.disabled]="currentPage === 0">
            <a class="page-link" href="#" (click)="onPageChange(currentPage - 1); $event.preventDefault()">Previous</a>
          </li>
          <li class="page-item" *ngFor="let page of getPages()" [class.active]="page === currentPage + 1">
            <a class="page-link" href="#" (click)="onPageChange(page - 1); $event.preventDefault()">{{page}}</a>
          </li>
          <li class="page-item" [class.disabled]="currentPage === totalPages - 1">
            <a class="page-link" href="#" (click)="onPageChange(currentPage + 1); $event.preventDefault()">Next</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="col-md-3">
      <div *ngIf="selectedUser" class="card">
        <div class="card-body">
          <h5 class="card-title">{{ selectedUser.firstName }} {{ selectedUser.lastName }}</h5>
          <p class="card-text">{{'idNumber' | translate}}: <b>{{ selectedUser.idNumber }}</b></p>
          <p class="card-text">{{'phoneNumber' | translate}}: <b>{{ selectedUser.phoneNumber }}</b></p>
          <p class="card-text">{{'email' | translate}}: <b>{{ selectedUser.email }}</b></p>
          <p class="card-text">{{'jobDescription' | translate}}: <b>{{ selectedUser.jobDescription }}</b></p>
          <div *ngIf="imagesData.length > 0" class="image-scroll-container">
            <div class="image-scroll-wrapper">
              <div *ngFor="let image of imagesData" class="image-item">
                <img [src]="image">
              </div>
            </div>
          </div>
          <div *ngIf="imagesData.length === 0 && !loading">
            <p>No images available</p>
          </div>
          <div *ngIf="loading" class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>