<div class="container-fluid d-flex justify-content-center align-items-center w-100">
	<div class="card m-0 shadow-sm border-white rounded my-auto w-100">
		<app-header></app-header>
		<div class="card-body w-100">
			<div class="d-flex flex-column justify-content-between align-items-center custom-card-height">
				<div class="mt-4 col-12 col-md-4">
					<div class="mt-4">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/consent/fp.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start">
								<span class="text-medium">{{'biometric.data' | translate}}</span>
							</div>
						</div>
					</div>

					<div class="border rounded-4 border-2 mt-4 p-4">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<span class="text-medium-regular">{{'consent' | translate}}</span>
							</div>
							<app-custom-toggle [(checked)]="consents['biometric']"
								(checkedChange)="onToggle('biometric', $event)" />
						</div>
					</div>

					<div class="mt-5">
						<div class="row">
							<div class="col-1">
								<img src="assets/images/angola/consent/terms.png" height="28px">
							</div>
							<div class="col-1"></div>
							<div class="col text-start">
								<span class="text-medium">{{'terms.and.conditions' | translate}}</span>
							</div>
						</div>
						<div class="row mt-2">
							<div class="col">
								<span class="text-small">
									{{'i.agree' | translate}} <span (click)="openTerms()" class="text-danger" style="cursor: pointer;">{{'click.here.to.read' | translate}}</span>
								</span>
							</div>
						</div>
					</div>

					<div class="border rounded-4 border-2 mt-4 p-4">
						<div class="d-flex justify-content-between align-items-center">
							<div>
								<span class="text-medium-regular">{{'consent' | translate}}</span>
							</div>
							<app-custom-toggle [(checked)]="consents['terms']"
								(checkedChange)="onToggle('terms', $event)" />
						</div>
					</div>
				</div>


				<div *ngIf="isLoading" class="spinner-grow text-danger" role="status">
					<span class="visually-hidden">Loading...</span>
				</div>

				<div class="text-center mt-4 mb-3 col-sm=12 col-md-4">
					<button [disabled]="!continuePossible" type="button" class="btn btn-angola" (click)="continue()">{{'continue' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>