<div class="container mt-5">
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card">
        <div *ngIf="isLoading" class="spinner-grow text-danger" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
        <div class="card-header">
          <h3 class="text-center">{{'change.password' | translate}}</h3>
        </div>
        <div class="card-body">
          <form (ngSubmit)="onSubmit()">
            <div class="form-group">
              <label for="oldPassword">{{'old.password' | translate}}</label>
              <app-custom-textfield isValid="true" type="password" [placeholder]="'old.password' | translate"
                name="oldPassword" (inputChange)="handleOldPasswordChange($event)">
              </app-custom-textfield>
            </div>
            <div class="form-group">
              <label for="newPassword">{{'new.password' | translate}}</label>
              <app-custom-textfield isValid="true" type="password" [placeholder]="'new.password' | translate"
                name="newPassword" (inputChange)="handleNewPasswordChange($event)">
              </app-custom-textfield>
            </div>
            <div class="form-group">
              <label for="confirmNewPassword">{{'confirm.new.password' | translate}}</label>
              <app-custom-textfield type="password" [placeholder]="'confirm.new.password' | translate"
                name="confirmNewPassword" [isValid]="newPasswordIsValid"
                (inputChange)="handleConfirmNewPasswordChange($event)">
              </app-custom-textfield>
            </div>
            <div class="row">
              <div class="col">
                <button type="submit" class="btn btn-secondary w-100 mt-3">{{'change.password' | translate}}</button>
              </div>
              <div class="col">
                <button type="submit" class="btn btn-danger w-100 mt-3" (click)="cancel()">{{'cancel' | translate}}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>