import { Component, EventEmitter, NgZone, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Data, Error, ErrorType, ImageTypeEnum, Member, ServerConfig } from "src/app/dto";
import { ConfigService } from "src/app/service/config.service";
import { HttpFacetecService } from "src/app/service/http-facetec.service";
import { RefService } from "src/app/service/ref.service";
import { Config as FacetecConfig } from "src/assets/Config";
import { SampleApp } from "src/assets/FaceTecAngularSampleApp/src/sampleAppController";
import { onIdResult } from "./utils";
import { DataService } from "src/app/service/data.service";
import { FacetecSDKService } from "src/app/service/facetec.sdk.service";
import { ToastrService } from "ngx-toastr";
import { TranslateService } from "@ngx-translate/core";
import { SampleAppUtilities } from "src/assets/FaceTecAngularSampleApp/src/utilities/SampleAppUtilities";

@Component({
    selector: "app-consent",
    templateUrl: "./consent.component.html",
    styleUrls: ["./consent.component.scss"],
})
export class ConsentComponent implements OnInit {
    onSessionToken: EventEmitter<any> = new EventEmitter();
    onPortraitResultData: EventEmitter<any> = new EventEmitter();
    onIdResultData: EventEmitter<any> = new EventEmitter();

    sessionTokenSubscription: Subscription;
    portraitResultSubscription: Subscription;
    idResultSubscription: Subscription;

    data: Data = {
        imageList: [],
    } as Data;

    config: ServerConfig;
    errorCount: number = 0;
    errors: Error[];

    sdkInitialised = false;
    isLoading = false;

    continuePossible: boolean = false;
    consents = {
        biometric: false,
        terms: false,
    };

    constructor(
        private router: Router,
        private toastr: ToastrService,
        private refService: RefService,
        private configService: ConfigService,
        private dataService: DataService,
        private facetecService: HttpFacetecService,
        private facetecSDKService: FacetecSDKService,
        private translateService: TranslateService,
        private zone: NgZone,
    ) {
        console.log("ConsentComponent constructor");
    }

    ngOnInit(): void {
        this.checkCanContinue();

        this.sessionTokenSubscription = this.onSessionToken.subscribe((result) => {
            this.isLoading = false;

            if (!result) {
                this.handleErrors("server.error.can.not.start.session", "");
            }
            this.data.sessionId = result;
            this.dataService.setData(this.data);
        });

        this.portraitResultSubscription = this.onPortraitResultData.subscribe((result) => {
            var portrait = this.data.imageList.filter(
                (item) => item.type === ImageTypeEnum.Portrait,
            )[0];
            if (portrait) {
                portrait.image = result;
                return;
            }
            this.data.imageList.push({
                image: result,
                type: ImageTypeEnum.Portrait,
            });
            this.dataService.setData(this.data);
        });

        this.idResultSubscription = this.onIdResultData.subscribe((result) => {
            onIdResult({
                result,
                dataService: this.dataService,
                handleErrors: this.handleErrors.bind(this),
                handleCompletelyDone: this.handleCompletelyDone.bind(this),
            });
        });
    }

    private handleCompletelyDone(): void {
        this.router.navigate(["/personal-data"]);
    }

    private handleErrors(translateKey: string, result?: string): boolean {
        this.translateService.get(translateKey).subscribe((res: string) => {
            let message = res;
            if (!res || res.length <= 0) {
                message = result;
            }

            this.toastr.error(message, "Error");
        });

        this.isLoading = false;
        return true;
    }

    private submitErrorAudit(errorType: ErrorType): void {}

    checkCanContinue() {
        if (!this.refService.getRef()) {
            this.handleErrors("invalid.reference", "");
            return;
        }

        if (this.consents.terms && this.consents.biometric && this.refService.getRef()) {
            this.continuePossible = true;
        } else {
            this.continuePossible = false;
        }

        console.log(this.continuePossible);
    }

    onToggle(name: string, newState: boolean) {
        this.consents[name] = newState;
        this.checkCanContinue();
        console.log(this.consents);
    }

    continue() {
        this.isLoading = true;
        if (this.sdkInitialised) {
            this.start();
        } else {
            this.facetecService.start(this.refService.getRef()).subscribe((response: any) => {
                if (response.code === 200) {
                    const data = response.data;
                    
                    console.log("Start Response: ", data);
                    const config = data.config as ServerConfig;
                    this.configService.setConfig(config);
                    
                    const member  = data.member as Member;
                    this.dataService.setMember(member);

                    if(!member) {
                        this.handleErrors("error.can.not.get.member");
                        return;
                    }

                    FacetecConfig.BaseURL = config.url;
                    FacetecConfig.DeviceKeyIdentifier = config.deviceKeyIdentifier;
                    FacetecConfig.PublicFaceScanEncryptionKey = config.publicFaceScanEncryptionKey;
                    FacetecConfig.ProductionKeyText = config.browserSdkLicense;
                    FacetecConfig.transactionId = config.transactionId;
                    FacetecConfig.requestNumber = 0;
                    FacetecConfig.authToken = config.token;
                    FacetecConfig.ref = this.refService.getRef();
                    FacetecConfig.facetecTag = `angola_${
                        FacetecConfig.ref
                    }_${SampleAppUtilities.generateUUId()}`;                    

                    this.facetecSDKService.initializeBrowserSDK({
                        onInitializeFailed: this.onInitializeFailed.bind(this),
                        onInitializeSuccess: this.onInitializeSuccess.bind(this),
                    });
                }
            });
        }
    }

    start() {
        SampleApp.onPhotoIDMatchPressed(
            this.onSessionToken,
            this.onPortraitResultData,
            this.onIdResultData,
        );
    }

    onInitializeSuccess() {
        console.log("onInitializeSuccess");
        this.sdkInitialised = true;
        this.start();
    }

    onInitializeFailed() {
        this.sdkInitialised = false;
        this.isLoading = false;
        this.handleErrors("error.can.not.init.facetec.sdk", "");
    }

    openTerms() {
        console.log("openTerms clicked");
        this.router.navigate(["/terms"]);
    }
}
