import { AngolaData, Commune, Country, Municipality, Province } from "./types";

export class AngolaAdminDivisions {
    private data: AngolaData;
    private countries: Country[];

    constructor(jsonData: string, countriesData: string) {
        this.data = JSON.parse(jsonData);
        this.countries = JSON.parse(countriesData);
    }

    getProvinces(): Province[] {
        return this.data.provinces;
    }

    getMunicipalities(provinceName: string): Municipality[] {
        const province = this.data.provinces.find((p) => p.name === provinceName);
        return province ? province.municipalities : [];
    }

    getCommunes(provinceName: string, municipalityName: string): Commune[] {
        const province = this.data.provinces.find((p) => p.name === provinceName);
        const municipality = province?.municipalities.find((m) => m.name === municipalityName);
        return municipality ? municipality.communes : [];
    }

    getCountries(): Country[] {
        return this.countries;
    }

    getCountryByPosition(position: number): Country | undefined {
        return this.countries.find((c) => c.position === position);
    }

    getCountryByName(name: string): Country | undefined {
        return this.countries.find((c) => c.name === name);
    }
}
