// custom-toggle.component.ts
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'app-custom-toggle',
  templateUrl: "./custom-toggle.component.html",
  styleUrls: ["./custom-toggle.component.scss"],	
})
export class CustomToggleComponent {  
  @Input() checked: boolean = false;
  @Output() checkedChange = new EventEmitter<boolean>();

  checkIcon = faCheck;
  xIcon = faXmark;
  
  onToggleChange() {
    this.checkedChange.emit(this.checked);
  }
}